<template>
  <div id="help">
    <!-- TITTLE -->
    <h1 class="a1g"
        :class="{ 'margin-webview': checkWebview(), 'padding-has-caution': isHasCaution }">ヘルプ</h1>

    <div class="wrapper">
      <!-- MENU LEFT -->
      <div class="nav">
        <div class="ttl_en a1g">FAQ</div>
        <div class="ttl_jp">よくあるご質問</div>
        <ul>
          <li v-for="(item, index) in menu"
              :key="index">
            <a @click="scrollFix(item.hash)" v-html="item.name"></a>
          </li>
        </ul>
      </div>

      <!-- CONTENT QUESTION -->
      <div class="contents">
        <div id="faq01"></div>
        <div class="box">
          <div class="ttl a1g">ChibiJob全般について</div>

          <div class="cp_actab">
            <input id="tab01-01" type="checkbox" name="tabs">
            <label for="tab01-01"><span>Q</span>
              <p>ChibiJobってどんなサービス？</p>
            </label>
            <div class="cp_actab-content">
              <div class="cp_actab-content__inner">
                <span>A</span>
                <p>『ChibiJob』とは与えてる条件を満たしてポイントを獲得することができるサービスです。<br>ChibiJobの詳細はご利用ガイドにてご確認いただきますようお願いいたします。</p>
              </div>
            </div>
          </div>

          <div class="cp_actab">
            <input id="tab01-02" type="checkbox" name="tabs">
            <label for="tab01-02"><span>Q</span>
              <p>友達紹介って何？</p>
            </label>
            <div class="cp_actab-content">
              <div class="cp_actab-content__inner">
                <span>A</span>
                <p>お友達にChibiJobをご紹介いただき、お友達がChibiJobに登録されると『友達紹介ポイント』を獲得できます。<br>また、X(旧Twitter)の場合は動画を見てシェアするだけでポイント獲得が可能です。<br>紹介方法・ポイント獲得の条件など詳しくは<router-link :to="{name: 'InviteFriend'}">こちら</router-link>へご確認くださいませ。</p>
              </div>
            </div>
          </div>

          <div class="cp_actab">
            <input id="tab01-03" type="checkbox" name="tabs">
            <label for="tab01-03"><span>Q</span>
              <p>ログインできない</p>
            </label>
            <div class="cp_actab-content">
              <div class="cp_actab-content__inner">
                <span>A</span>
                <p>ログインが出来ない場合、メールアドレスまたはパスワードのご入力に誤りがある可能性がございます。<br>恐れ入りますが再度ご確認をお願いいたします。<br><br>
                  ■メールアドレスがわからない場合<br>
                  ご登録の可能性がある全てのメールアドレスにてログインをお試しください。<br>
                  <br>
                  ■パスワードがわからない場合<br>
                  パスワードの再設定をお願いいたします。<br>
                  <router-link :to="{name: 'ChangeEmail'}">こちら</router-link>よりご登録メールアドレスをご入力いただき、ご変更をお願いいたします。<br>
                  <br>
                  上記をお試しいただいた上でログインができない場合は、経緯の詳細を記載の上、お問い合わせフォームよりお問い合わせください。</p>
              </div>
            </div>
          </div>

          <div class="cp_actab">
            <input id="tab01-04" type="checkbox" name="tabs">
            <label for="tab01-04"><span>Q</span>
              <p>ChibiJobからのメールが届かない</p>
            </label>
            <div class="cp_actab-content">
              <div class="cp_actab-content__inner">
                <span>A</span>
                <p>自動的に迷惑メールフォルダ等に振り分けられているなど、セキュリティ設定が影響している可能性がございます。<br>
                  ご利用のメールサービスの受信設定やセキュリティ設定をご確認いただけますでしょうか。<br>
                  <br>
                  ChibiJobからのメールのドメインは「*****@chibijob.com」となります。<br>
                  「chibijob.com」からのメールを受信していただけるよう設定をご確認くださいませ。</p>
              </div>
            </div>
          </div>

          <div class="cp_actab">
            <input id="tab01-05" type="checkbox" name="tabs">
            <label for="tab01-05"><span>Q</span>
              <p>どんなメールが届くの？</p>
            </label>
            <div class="cp_actab-content">
              <div class="cp_actab-content__inner">
                <span>A</span>
                <p>ChibiJobからのメールマガジンは、主にお得なキャンペーンやおすすめ広告のご紹介情報を配信しています。<br>なお、メール頻度は不定期で配信しております。</p>
              </div>
            </div>
          </div>

          <div class="cp_actab">
            <input id="tab01-06" type="checkbox" name="tabs">
            <label for="tab01-06"><span>Q</span>
              <p>退会後もメールが届く</p>
            </label>
            <div class="cp_actab-content">
              <div class="cp_actab-content__inner">
                <span>A</span>
                <p>システムの都合上、退会後数日間はメールが届いてしまう場合がございます。<br>恐れ入りますが、ご了承ください。</p>
              </div>
            </div>
          </div>

          <div class="cp_actab">
            <input id="tab01-07" type="checkbox" name="tabs">
            <label for="tab01-07"><span>Q</span>
              <p>推奨環境を知りたい</p>
            </label>
            <div class="cp_actab-content">
              <div class="cp_actab-content__inner">
                <span>A</span>
                <p>ChibiJobをご利用時の推奨環境は以下の通りです。<br>
                  ---<br>
                  ・iOS端末をご利用の方：Safari<br>
                  ・Android端末をご利用の方：Google Chrome<br>
                  ・PCをご利用の方：Google Chrome / Firefox / Safari<br>
                  ---<br>
                  <br>
                  ※プライベートブラウザ機能がOFFに設定された状態でご利用ください。<br>
                  ※メーラーアプリやSNSアプリのブラウザ機能で広告をご利用になった場合、正常にポイントが反映されない可能性がございます。</p>
              </div>
            </div>
          </div>

          <div class="cp_actab">
            <input id="tab01-08" type="checkbox" name="tabs">
            <label for="tab01-08"><span>Q</span>
              <p>日本国外で利用したい</p>
            </label>
            <div class="cp_actab-content">
              <div class="cp_actab-content__inner">
                <span>A</span>
                <p>ChibiJobは日本国内のみ対応しております。<br>
                  海外からは正常にご利用できない可能性がございますため、保証・サポートは行っておりません。<br>
                  恐れ入りますが、日本国内のみでご利用いただけますようお願いいたします。</p>
              </div>
            </div>
          </div>

          <div class="cp_actab">
            <input id="tab01-09" type="checkbox" name="tabs">
            <label for="tab01-09"><span>Q</span>
              <p>電話で問い合わせしたい</p>
            </label>
            <div class="cp_actab-content">
              <div class="cp_actab-content__inner">
                <span>A</span>
                <p>ChibiJobでは、本人確認が必要なため、お電話やご来社でのお問い合わせ窓口を設けておりません。<br>
                  お客様からのお問い合わせは全てメールにて行っております。<br>
                  恐れ入りますが、お問い合わせフォームよりお願いいたします。</p>
              </div>
            </div>
          </div>

          <div class="cp_actab">
            <input id="tab01-10" type="checkbox" name="tabs">
            <label for="tab01-10"><span>Q</span>
              <p>問い合せはどこからできる？</p>
            </label>
            <div class="cp_actab-content">
              <div class="cp_actab-content__inner">
                <span>A</span>
                <p><router-link :to="{name: 'ContactForm'}">こちら</router-link>より承っております。
                  ご希望のお問合せの種類を選択いただき、詳細をご入力の上、ご連絡をお願いいたします。<br>
                  <br>
                  ※お問い合わせの前にまず【ヘルプ】や【ご利用ガイド】、各広告詳細の【獲得条件】をご確認くださいませ。<br>
                  ※お問合せ対応の営業時間は、10：00～18：00（土曜、日曜、祝日を除く）となります。<br>
                  なお、回答は5営業日程度のお時間を頂いております。<br>
                  お問合せ内容によって、回答にお時間を要する・回答が出来かねる場合がございますので、予めご了承ください。
                </p>
              </div>
            </div>
          </div>

        </div>
        <div id="faq02"></div>
        <div class="box">
          <div class="ttl a1g">ポイントについて</div>
          <div class="cp_actab">
            <input id="tab02-01" type="checkbox" name="tabs">
            <label for="tab02-01"><span>Q</span>
              <p>ポイント明細の判定中に反映されない</p>
            </label>
            <div class="cp_actab-content">
              <div class="cp_actab-content__inner">
                <span>A</span>
                <p>各広告ページの「予定反映」に記載のある時期を目安に[判定中]へ反映されます。<br>
                  予定反映"なし"と記載のある広告は[判定中]への反映は行われません。<br>
                  獲得条件を満たした状態で、「予定反映」に記載のある時期を過ぎても[判定中]へ反映されない場合は、お問い合わせフォームよりお問い合わせいただきますようお願いいたします。<br>
                  <br>
                  なお、広告によって調査を承ることができない広告がございます。予めご了承いただきますようお願いいたします。</p>
              </div>
            </div>
          </div>
          <div class="cp_actab">
            <input id="tab02-02" type="checkbox" name="tabs">
            <label for="tab02-02"><span>Q</span>
              <p>ポイントが入らない</p>
            </label>
            <div class="cp_actab-content">
              <div class="cp_actab-content__inner">
                <span>A</span>
                <p>判定期間中の可能性もございます。マイページのポイント通帳にてまずは現在のステータスをご確認くださいませ。<br>
                  なお、ChibiJob経由でのご利用データが正常に発生していない場合や否認となった場合はポイントが入りませんのでご注意くださいませ。<br>
                  <br>
                  &lt;データが正常に発生しない例&gt;<br>
                    ・ご利用完了までにセッションが切れた、時間がかかった<br>
                    ・途中で他のタブやサイトを開いた<br>
                    ・ご利用完了まで同一ブラウザで行っていない<br>
                    ・プライベートモードでChibiJobを開いた<br>
                    ・Cookieが有効になっていない<br>
                    ・他サイト経由のCookieが優先されている<br>
                    ・不安定な通信環境でのご利用<br>
                    ・ウイルスソフトや広告ブロックツールを使用<br>
                    ・ブラウザのITP設定がOFFされていない<br>
                    ※上記はあくまで一例となります。
                </p>
              </div>
            </div>
          </div>
          <div class="cp_actab">
            <input id="tab02-03" type="checkbox" name="tabs">
            <label for="tab02-03"><span>Q</span>
              <p>いつポイントがもらえる？</p>
            </label>
            <div class="cp_actab-content">
              <div class="cp_actab-content__inner">
                <span>A</span>
                <p>各広告ページに[予定反映]と[確定反映]の記載がございます。<br>
                  [予定反映]の時期を目安に[ポイント明細]-[判定中]へ反映され、<br>
                  [確定時期]の時期を目安にポイント獲得となります。<br>
                  <br>
                  広告主による判定次第で確定反映となるため、記載時期から前後する可能性がございます。<br>
                  あくまで目安としてご参照くださいませ。</p>
              </div>
            </div>
          </div>
          <div class="cp_actab">
            <input id="tab02-04" type="checkbox" name="tabs">
            <label for="tab02-04"><span>Q</span>
              <p>判定中のままポイントが入らない</p>
            </label>
            <div class="cp_actab-content">
              <div class="cp_actab-content__inner">
                <span>A</span>
                <p>広告によって確定時期が異なります。<br>
                  各広告ページの「確定反映」時期をご確認いただきますようお願いいたします。<br>
                  なお、広告主による判定次第で確定反映となるため、記載時期から前後する可能性がございます。</p>
              </div>
            </div>
          </div>
          <div class="cp_actab">
            <input id="tab02-05" type="checkbox" name="tabs">
            <label for="tab02-05"><span>Q</span>
              <p>条件を満たしているはずなのに否認になった！</p>
            </label>
            <div class="cp_actab-content">
              <div class="cp_actab-content__inner">
                <span>A</span>
                <p>結果が否認となった場合は今一度、獲得条件をご確認くださいませ。<br>
                  <br>
                  獲得条件を確認した上で、条件を満たしているにも関わらず否認となっている場合は、否認理由を確認させていただきます。<br>
                  お手数ですが、お問い合わせフォームよりご連絡いただきますようお願いいたします。<br>
                  <br>
                  なお、広告によって調査を承ることができない広告がございます。予めご了承いただきますようお願いいたします。</p>
              </div>
            </div>
          </div>
          <div class="cp_actab">
            <input id="tab02-06" type="checkbox" name="tabs">
            <label for="tab02-06"><span>Q</span>
              <p>判定結果はどこで確認できる？？</p>
            </label>
            <div class="cp_actab-content">
              <div class="cp_actab-content__inner">
                <span>A</span>
                <p>マイページ内のポイント通帳にて「承認」・「否認」という項目がございます。<br>
                  広告主による判定の結果は、承認となった場合は「承認」へ、否認となった場合は「否認」へ反映されます。</p>
              </div>
            </div>
          </div>
          <div class="cp_actab">
            <input id="tab02-07" type="checkbox" name="tabs">
            <label for="tab02-07"><span>Q</span>
              <p>確定明細に反映されたポイント数が異なっている</p>
            </label>
            <div class="cp_actab-content">
              <div class="cp_actab-content__inner">
                <span>A</span>
                <p>広告の掲載期間・ご利用条件・ポイント数等の内容は随時変更がございます。
                  広告ご利用時の条件が適用されますことご了承くださいませ。<br>
                  なお、消費税・送料・手数料等は対象外となります。<br>
                  ポイントや割引券等のご利用時の割引分も対象外となる場合がございます。</p>
              </div>
            </div>
          </div>
          <div class="cp_actab">
            <input id="tab02-08" type="checkbox" name="tabs">
            <label for="tab02-08"><span>Q</span>
              <p>割引やクーポンの利用分はポイント獲得の対象になる？</p>
            </label>
            <div class="cp_actab-content">
              <div class="cp_actab-content__inner">
                <span>A</span>
                <p>割引やクーポンのご利用額は各広告の獲得条件に特記がない場合は、基本的にポイント獲得の対象外となります。<br>
                  各広告の獲得条件をご確認くださいませ。</p>
              </div>
            </div>
          </div>
          <div class="cp_actab">
            <input id="tab02-09" type="checkbox" name="tabs">
            <label for="tab02-09"><span>Q</span>
              <p>獲得ポイントを確認したい</p>
            </label>
            <div class="cp_actab-content">
              <div class="cp_actab-content__inner">
                <span>A</span>
                <p>獲得ポイントはマイページのポイント通帳よりご確認いただけます。</p>
              </div>
            </div>
          </div>
          <div class="cp_actab">
            <input id="tab02-10" type="checkbox" name="tabs">
            <label for="tab02-10"><span>Q</span>
              <p>ポイントの有効期限はある？</p>
            </label>
            <div class="cp_actab-content">
              <div class="cp_actab-content__inner">
                <span>A</span>
                <p>定期的にポイントを獲得して頂く限り、有効期限はございません。<br>
                  ただし、「利用規約 第14条 ポイントの取消」にもございます通り、最後にポイントを獲得した日から180日が経過した場合は、ポイントの失効となりますのでご注意ください。</p>
              </div>
            </div>
          </div>
          <div class="cp_actab">
            <input id="tab02-10-1" type="checkbox" name="tabs">
            <label for="tab02-10-1"><span>Q</span>
              <p>ポイント失効日延長の対象を教えてください</p>
            </label>
            <div class="cp_actab-content">
              <div class="cp_actab-content__inner">
                <span>A</span>
                <p>広告利用に付与されたポイントのみ対象となります。<br>
                  X(旧Twitter)シェア、友達紹介、ボーナスポイント、デイリーゲットポイントはポイント失効日延長の対象外となりますので、ご了承ください。</p>
              </div>
            </div>
          </div>
          <div class="cp_actab">
            <input id="tab02-11" type="checkbox" name="tabs">
            <label for="tab02-11"><span>Q</span>
              <p>交換していないのにポイントがなくなっていた</p>
            </label>
            <div class="cp_actab-content">
              <div class="cp_actab-content__inner">
                <span>A</span>
                <p>「利用規約 第14条 ポイントの取消」にもございます通り、会員様のポイント獲得が180日以上確認できない場合には、ポイントの失効となります。</p>
              </div>
            </div>
          </div>
          <div class="cp_actab">
            <input id="tab02-11-1" type="checkbox" name="tabs">
            <label for="tab02-11-1"><span>Q</span>
              <p>ポイントの合算はできる？</p>
            </label>
            <div class="cp_actab-content">
              <div class="cp_actab-content__inner">
                <span>A</span>
                <p>スマートフォン版・PC版で別々にご登録がされている場合、ポイントやユーザー情報、履歴などの合算ができません。ご了承ください。<br>
                  また、アカウントを重複登録してしまった場合は、退会手続きを行っていただけますようお願いいたします。</p>
              </div>
            </div>
          </div>
          <div class="cp_actab">
            <input id="tab02-12" type="checkbox" name="tabs">
            <label for="tab02-12"><span>Q</span>
              <p>アプリの広告を利用後、途中で機種変更してしまった。</p>
            </label>
            <div class="cp_actab-content">
              <div class="cp_actab-content__inner">
                <span>A</span>
                <p>インストールした端末とは別の端末にデータを引き継いで成果地点まで到達した場合、インストールした端末に別のデータを引き継いで成果地点まで到達した場合に該当しているため、獲得対象外です。<br>
                  <br>
                  また、機種変更後の新しい端末にて再度アプリをインストールした際も過去にアプリをダウンロードしたことがある場合に該当のため、獲得対象外です。<br>
                  <br>
                  機種変更のご予定がある場合は、機種変更完了後に、広告ご利用いただきますようお願いいたします。</p>
              </div>
            </div>
          </div>
          <div class="cp_actab">
            <input id="tab02-13" type="checkbox" name="tabs">
            <label for="tab02-13"><span>Q</span>
              <p>サイト登録後、すぐに退会してもポイントはもらえる？</p>
            </label>
            <div class="cp_actab-content">
              <div class="cp_actab-content__inner">
                <span>A</span>
                <p>広告主/サイトより早期退会の処理が異なりますが、ポイントが回収される場合もございますので、ご注意ください。</p>
              </div>
            </div>
          </div>
          <div class="cp_actab">
            <input id="tab02-14" type="checkbox" name="tabs">
            <label for="tab02-14"><span>Q</span>
              <p>広告利用の調査はできないと回答された</p>
            </label>
            <div class="cp_actab-content">
              <div class="cp_actab-content__inner">
                <span>A</span>
                <p>広告の中には、ポイントについての調査ができない広告がございます。<br>
                  ご利用前に、各広告の詳細ページにてご確認いただきますようお願いいたします。<br>
                  <br>
                  ■調査ができない例<br>
                  ・広告主側で調査を受付していない場合<br>
                  ・広告ご利用日からの調査受付期限を超過している場合<br>
                  ・広告のご利用日が確認できない場合<br>
                  ・ChibiJob経由のアクセスが確認できない場合<br>
                  ・ポイント獲得条件を満たしていない場合、却下条件に抵触する場合<br>
                  ・広告主から配信されたメールの確認ができない場合<br>
                  <br>
                  ※広告主から配信されたメール（申込受付完了メール、購入完了メールなど）は、ポイント付与が完了するまでエビデンスとして必ず保管をお願いいたします。<br>
                  メールを破棄された場合、調査ができなくなりますので、ご注意くださいませ。</p>
              </div>
            </div>
          </div>
          <div class="cp_actab">
            <input id="tab02-15" type="checkbox" name="tabs">
            <label for="tab02-15"><span>Q</span>
              <p>申し込み時から、広告のポイントや条件が変わっている</p>
            </label>
            <div class="cp_actab-content">
              <div class="cp_actab-content__inner">
                <span>A</span>
                <p>広告のポイント数・ご利用条件・掲載期間等の内容は随時変更がございます。<br>
                  広告ご利用時の条件が適用されますこと、予めご了承くださいませ。</p>
              </div>
            </div>
          </div>
          <div class="cp_actab">
            <input id="tab02-16" type="checkbox" name="tabs">
            <label for="tab02-16"><span>Q</span>
              <p>利用していない広告のポイントが判定中になっている</p>
            </label>
            <div class="cp_actab-content">
              <div class="cp_actab-content__inner">
                <span>A</span>
                <p>Cookieの機能等、何らかの原因により、ChibiJob経由で広告ご利用履歴の確認が取れたため、ポイント通帳へ反映されたかと存じます。<br>
                  判定中に表示されても承認されない場合は後程無効となりますので、反映されるまで今しばらくお待ちいただきますようお願いいたします。</p>
              </div>
            </div>
          </div>
          <div class="cp_actab">
            <input id="tab02-17" type="checkbox" name="tabs">
            <label for="tab02-17"><span>Q</span>
              <p>アプリダウンロードのポイントが反映されない</p>
            </label>
            <div class="cp_actab-content">
              <div class="cp_actab-content__inner">
                <span>A</span>
                <p>ChibiJobからのご利用が確認出来ない場合、ポイントの対象外となります。
                  また、下記内容をご注意のうえご利用くださいませ。<br>
                  ----<br>
                  ※初めて対象アプリをダウンロードされる方のみ対象となります。<br>
                  ※ダウンロードまで同一のブラウザにて行った場合が対象となります。<br>
                  ※上限件数に達し次第終了となります。<br>
                  ※インストールから起動までに時間が経ってしまった場合や、起動完了までにアプリ側の予算上限に達した場合は、対象外となります。<br>
                  ※起動後、すぐにアプリを閉じると対象外となる場合があります。1分以上は開いた状態にしてください。<br>
                  ---<br>
                  細かな条件が設定されているアプリもございますため、条件をご確認のうえご利用いただけますようお願いいたします。</p>
              </div>
            </div>
          </div>
          <div class="cp_actab">
            <input id="tab02-18" type="checkbox" name="tabs">
            <label for="tab02-18"><span>Q</span>
              <p>ブラウザのcookie／キャッシュを削除する方法は？</p>
            </label>
            <div class="cp_actab-content">
              <div class="cp_actab-content__inner">
                <span>A</span>
                <p>ChibiJobのポイントはブラウザ上のcookie等の利用にて計測しているため、
                  別サイトの広告等を閲覧していた場合に、ブラウザにcookieやキャッシュが残ってしまうと、ポイント通帳に正しく反映できなくなる場合がございます。<br>
                  広告のご利用前にcookie/キャッシュの削除をお試しください。<br>
                  <br>
                  ■cookie/キャッシュの削除方法■<br>
                  <br>
                  ・PCの場合<br>
                  ご利用ブラウザの「設定」より<br>
                  プライバシー>「閲覧履歴データの削除」<br>
                  ※ご利用のブラウザによって方法が異なります。<br>
                  <br>
                  ・スマートフォンの場合<br>
                  端末の「設定」アプリより<br>
                  該当ブラウザアプリを選択>「履歴とwebサイトデータを削除」<br>
                  ※ご利用の端末、ブラウザによって方法が異なります。</p>
              </div>
            </div>
          </div>
          <div class="cp_actab">
            <input id="tab02-19" type="checkbox" name="tabs">
            <label for="tab02-19"><span>Q</span>
              <p>現時点で条件を達成できているか知りたい</p>
            </label>
            <div class="cp_actab-content">
              <div class="cp_actab-content__inner">
                <span>A</span>
                <p>恐れ入りますが判定中の案件について、獲得条件の達成状況をお調べすることはできません。<br>
                  獲得条件をご確認の上、条件を達成しているかお客様ご自身でご確認いただきますようお願いいたします。</p>
              </div>
            </div>
          </div>
          <div class="cp_actab">
            <input id="tab02-20" type="checkbox" name="tabs">
            <label for="tab02-20"><span>Q</span>
              <p>広告主にポイントについて問い合わせたい</p>
            </label>
            <div class="cp_actab-content">
              <div class="cp_actab-content__inner">
                <span>A</span>
                <p>広告主にポイントに関するお問い合わせをされることは禁止行為とさせていただいております。<br>
                  広告主へのお問い合わせが発覚した場合は、ポイント対象外・ポイント没収等の対処をさせていただく場合もございますので、ご注意ください。<br>
                  <br>
                  ※ChibiJob運営事務局より、広告主へお問い合わせいただくようご案内する場合がございます。その場合はこの限りではございません。</p>
              </div>
            </div>
          </div>
        </div>
        <div id="faq03"></div>
        <div class="box">
          <div class="ttl a1g">ポイント交換について</div>
          <div class="cp_actab">
            <input id="tab03-01" type="checkbox" name="tabs">
            <label for="tab03-01"><span>Q</span>
              <p>ポイントは何に交換できる？</p>
            </label>
            <div class="cp_actab-content">
              <div class="cp_actab-content__inner">
                <span>A</span>
                <p>ポイント交換先は「Gポイント」「ドットマネー」「ギフトパッド」です。<br>
                  詳しくは<router-link :to="{name: 'ExchangePoint'}">こちら</router-link>をご確認くださいませ。</p>
              </div>
            </div>
          </div>
          <div class="cp_actab">
            <input id="tab03-02" type="checkbox" name="tabs">
            <label for="tab03-02"><span>Q</span>
              <p>ポイント交換に手数料は必要？</p>
            </label>
            <div class="cp_actab-content">
              <div class="cp_actab-content__inner">
                <span>A</span>
                <p>基本的にChibiJobポイントから交換先への手数料は掛かりません。<br>
                  「Gポイント」「ドットマネー」に関しては、交換後、更にポイントを交換する際に手数料が掛かる可能性がございます。<br>
                  詳しくは、各交換先のご案内をご確認くださいませ。
                </p>
              </div>
            </div>
          </div>
          <div class="cp_actab">
            <input id="tab03-03" type="checkbox" name="tabs">
            <label for="tab03-03"><span>Q</span>
              <p>1ポイントって何円？</p>
            </label>
            <div class="cp_actab-content">
              <div class="cp_actab-content__inner">
                <span>A</span>
                <p>ChibiJobでは1ポイント＝1円相当となります。<br>※交換先によってはレートが異なる場合がございますので、各種交換先をご確認いただきますようお願いいたします。</p>
              </div>
            </div>
          </div>
          <div class="cp_actab">
            <input id="tab03-04" type="checkbox" name="tabs">
            <label for="tab03-04"><span>Q</span>
              <p>ポイント交換額の上限は？</p>
            </label>
            <div class="cp_actab-content">
              <div class="cp_actab-content__inner">
                <span>A</span>
                <p>基本的には上限はございません。<br>
                  ただし、1度の交換あたりの上限額はございます。<br>
                  Gポイント及びドットマネーの上限額を10,000ptまでとさせていただきます。<br>
                  Gift Padの上限額を15,550ptまでとさせていただきます。</p>
              </div>
            </div>
          </div>
          <div class="cp_actab">
            <input id="tab03-05" type="checkbox" name="tabs">
            <label for="tab03-05"><span>Q</span>
              <p>ポイント交換何ポイントから？</p>
            </label>
            <div class="cp_actab-content">
              <div class="cp_actab-content__inner">
                <span>A</span>
                <p>交換先により異なります。<br>
                  詳しくは<router-link :to="{name: 'ExchangePoint'}">こちら</router-link>をご確認くださいませ。</p>
              </div>
            </div>
          </div>
          <div class="cp_actab">
            <input id="tab03-06" type="checkbox" name="tabs">
            <label for="tab03-06"><span>Q</span>
              <p>交換履歴を確認したい</p>
            </label>
            <div class="cp_actab-content">
              <div class="cp_actab-content__inner">
                <span>A</span>
                <p>マイページ内の「ポイント通帳>ポイント交換履歴」よりご確認いただけます。</p>
              </div>
            </div>
          </div>
          <div class="cp_actab">
            <input id="tab03-07" type="checkbox" name="tabs">
            <label for="tab03-07"><span>Q</span>
              <p>Gポイントギフトに交換したけど反映されない</p>
            </label>
            <div class="cp_actab-content">
              <div class="cp_actab-content__inner">
                <span>A</span>
                <p>「Gポイントギフト」に交換完了後は、マイページ内の「ポイント交換履歴」に表示されるコードをご確認ください。<br>ChibiJobでのお手続き完了後は「Gポイントへチャージする」にてお手続きをお願いいたします。</p>
              </div>
            </div>
          </div>
        </div>
        <div id="faq04"></div>
        <div class="box">
          <div class="ttl a1g">広告利用について</div>
          <div class="cp_actab">
            <input id="tab04-01" type="checkbox" name="tabs">
            <label for="tab04-01"><span>Q</span>
              <p>ChibiJob経由で登録したサイトの退会方法は？</p>
            </label>
            <div class="cp_actab-content">
              <div class="cp_actab-content__inner">
                <span>A</span>
                <p>退会のお手続きはご自身で行っていただきますようお願いいたします。</p>
              </div>
            </div>
          </div>
          <div class="cp_actab">
            <input id="tab04-02" type="checkbox" name="tabs">
            <label for="tab04-02"><span>Q</span>
              <p>同じ広告を2回目も利用しても良い？</p>
            </label>
            <div class="cp_actab-content">
              <div class="cp_actab-content__inner">
                <span>A</span>
                <p>広告によって、初回のご利用のみがポイント対象になる広告と、2回目以降のご利用もポイント対象になる広告がございます。<br>広告ご利用前に、各広告のポイント獲得条件、注意事項をご確認ください。
                </p>
              </div>
            </div>
          </div>
          <div class="cp_actab">
            <input id="tab04-03" type="checkbox" name="tabs">
            <label for="tab04-03"><span>Q</span>
              <p>ChibiJobからお申し込み受付メールが来ない</p>
            </label>
            <div class="cp_actab-content">
              <div class="cp_actab-content__inner">
                <span>A</span>
                <p>広告のお申し込み完了メールをChibiJobからお送りすることはございません。<br>お申し込み日時やお申し込み内容はご自身で管理していただきますようお願いいたします。</p>
              </div>
            </div>
          </div>
          <div class="cp_actab">
            <input id="tab04-04" type="checkbox" name="tabs">
            <label for="tab04-04"><span>Q</span>
              <p>広告を利用する際のの注意事項は？</p>
            </label>
            <div class="cp_actab-content">
              <div class="cp_actab-content__inner">
                <span>A</span>
                <p>広告の詳細ページにございます「ポイント獲得に関する注意事項」をご確認くださいませ。</p>
              </div>
            </div>
          </div>
          <div class="cp_actab">
            <input id="tab04-05" type="checkbox" name="tabs">
            <label for="tab04-05"><span>Q</span>
              <p>広告ページに「済」マークが付いている</p>
            </label>
            <div class="cp_actab-content">
              <div class="cp_actab-content__inner">
                <span>A</span>
                <p>一度ご利用されて、ポイント獲得済みの広告に済マークが表示されます。<br>
                  システムの不具合で、既にご利用いただいている広告に済マークが表示されない場合もございます。<br>
                  ご利用広告の管理はご自身で行っていただきますようお願いいたします。</p>
              </div>
            </div>
          </div>
          <div class="cp_actab">
            <input id="tab04-06" type="checkbox" name="tabs">
            <label for="tab04-06"><span>Q</span>
              <p>プライベート/シークレットモードで利用しても良い？</p>
            </label>
            <div class="cp_actab-content">
              <div class="cp_actab-content__inner">
                <span>A</span>
                <p>正常にデータを確認出来ない場合がございます。プライベート/シークレットモードでのご利用は避けていただきますようお願いいたします。</p>
              </div>
            </div>
          </div>
          <div class="cp_actab">
            <input id="tab04-07" type="checkbox" name="tabs">
            <label for="tab04-07"><span>Q</span>
              <p>ChibiJobに登録していないメールアドレスで広告を利用してしまった</p>
            </label>
            <div class="cp_actab-content">
              <div class="cp_actab-content__inner">
                <span>A</span>
                <p>基本的にChibiJobを経由してお申込みいただければ、ご登録いただいていないメールアドレスにて広告をご利用いただいても問題ございません。<br>ただし、広告によっては獲得条件にご登録いただいたメールアドレスをご利用いただくよう記載があるためご注意ください。</p>
              </div>
            </div>
          </div>
          <div class="cp_actab">
            <input id="tab04-08" type="checkbox" name="tabs">
            <label for="tab04-08"><span>Q</span>
              <p>パソコンとスマートフォン、両方で使える？</p>
            </label>
            <div class="cp_actab-content">
              <div class="cp_actab-content__inner">
                <span>A</span>
                <p>パソコン、スマートフォンの両方でご利用いただけます。</p>
              </div>
            </div>
          </div>
          <div class="cp_actab">
            <input id="tab04-09" type="checkbox" name="tabs">
            <label for="tab04-09"><span>Q</span>
              <p>同居している人と同じ端末（PC/スマホ）で利用しても大丈夫？</p>
            </label>
            <div class="cp_actab-content">
              <div class="cp_actab-content__inner">
                <span>A</span>
                <p>同居されている方と同じ端末でご利用いただくことは問題ございません。<br>
                  ただしアカウントの共有ご利用（ご名義の方以外のご利用）をご遠慮いただいております。<br>
                  同じ端末でご利用いただく場合は、アカウントの管理にご注意くださいませ。</p>
              </div>
            </div>
          </div>
          <div class="cp_actab">
            <input id="tab04-10" type="checkbox" name="tabs">
            <label for="tab04-10"><span>Q</span>
              <p>家族・知り合いの名義で広告申込しても良い？</p>
            </label>
            <div class="cp_actab-content">
              <div class="cp_actab-content__inner">
                <span>A</span>
                <p>「利用規約 第5条 会員資格」にもございます通り、サービスをご利用できるのは原則アカウントを所持されているご本人様のみとなります。<br>
                  ご本人様以外のご利用でポイントを獲得された場合、ポイントを付与することは出来ません。<br>
                  家族皆様でご利用される場合はご利用者様毎にアカウントの作成をお願いいたします。</p>
              </div>
            </div>
          </div>
        </div>
        <div id="faq05"></div>
        <div class="box">
          <div class="ttl a1g">会員登録情報について</div>
          <div class="cp_actab">
            <input id="tab05-01" type="checkbox" name="tabs">
            <label for="tab05-01"><span>Q</span>
              <p>秘密の質問を変えたい</p>
            </label>
            <div class="cp_actab-content">
              <div class="cp_actab-content__inner">
                <span>A</span>
                <p>秘密の質問はマイページ「登録情報変更」より再設定可能です。</p>
              </div>
            </div>
          </div>
          <div class="cp_actab">
            <input id="tab05-02" type="checkbox" name="tabs">
            <label for="tab05-02"><span>Q</span>
              <p>パスワードを忘れた</p>
            </label>
            <div class="cp_actab-content">
              <div class="cp_actab-content__inner">
                <span>A</span>
                <p>パスワードをお忘れの場合は、パスワード変更用URL送信ページよりご登録メールアドレスをご入力ください。<br>『[ChibiJob]パスワード変更』という件名のメールが届きますので、そちらのメール本文内に記載してあるURLへアクセスし、案内に従ってパスワードの変更お手続きをお願いいたします。
                </p>
              </div>
            </div>
          </div>
          <div class="cp_actab">
            <input id="tab05-03" type="checkbox" name="tabs">
            <label for="tab05-03"><span>Q</span>
              <p>パスワードを確認・変更したい</p>
            </label>
            <div class="cp_actab-content">
              <div class="cp_actab-content__inner">
                <span>A</span>
                <p>ChibiJob側ではお客様のパスワードを確認することが出来ません。<br>
                  パスワードを忘れた方は、パスワードを変更していただきますようお願いいたします。<br>
                  パスワードの変更方法は下記をご確認くださいませ。<br>
                  <br>
                  1.ログアウトされた状態でパスワード変更用URL送信ページよりご登録メールアドレスをご入力<br>
                  2.『[ChibiJob]パスワード変更』という件名のメールに記載のあるURLへアクセス<br>
                  3.案内に従い、パスワード変更お手続きを進めて頂く</p>
              </div>
            </div>
          </div>
          <div class="cp_actab">
            <input id="tab05-04" type="checkbox" name="tabs">
            <label for="tab05-04"><span>Q</span>
              <p>パスワード変更メールが届かない</p>
            </label>
            <div class="cp_actab-content">
              <div class="cp_actab-content__inner">
                <span>A</span>
                <p>自動的に迷惑メールフォルダ等に振り分けられているなど、セキュリティ設定が影響している可能性がございます。<br>
                  ご利用のメールサービスの受信設定やセキュリティ設定をご確認いただけますでしょうか。<br>
                  <br>
                  ChibiJobからのメールのドメインは「*****@chibijob.com」となります。<br>
                  「chibijob.com」からのメールを受信していただけるよう設定をご確認くださいませ。</p>
              </div>
            </div>
          </div>
          <div class="cp_actab">
            <input id="tab05-05" type="checkbox" name="tabs">
            <label for="tab05-05"><span>Q</span>
              <p>登録情報を変更したらポイントは失効する？</p>
            </label>
            <div class="cp_actab-content">
              <div class="cp_actab-content__inner">
                <span>A</span>
                <p>登録情報を変更いただいても、ポイントが失効することはございません。<br>
                  変更されたい方は、マイページの登録情報変更よりお手続きしていただきますようお願いいたします。<br>
                  <br>
                  ※ご利用いただいた広告によっては、登録情報を変更した場合、ポイント対象外となる場合がございます。<br>
                  変更する前に、ご利用いただいた広告の獲得条件をご確認いただきますようお願いいたします。</p>
              </div>
            </div>
          </div>
          <div class="cp_actab">
            <input id="tab05-06" type="checkbox" name="tabs">
            <label for="tab05-06"><span>Q</span>
              <p>生年月日の変更をしたい</p>
            </label>
            <div class="cp_actab-content">
              <div class="cp_actab-content__inner">
                <span>A</span>
                <p>一度ご登録いただいた生年月日を変更することはできません。</p>
              </div>
            </div>
          </div>
          <div class="cp_actab">
            <input id="tab05-07" type="checkbox" name="tabs">
            <label for="tab05-07"><span>Q</span>
              <p>会員登録に年齢制限はある？</p>
            </label>
            <div class="cp_actab-content">
              <div class="cp_actab-content__inner">
                <span>A</span>
                <p>利用規約の通り、入会登録者が12歳未満の場合はご利用いただけません。</p>
              </div>
            </div>
          </div>
          <div class="cp_actab">
            <input id="tab05-08" type="checkbox" name="tabs">
            <label for="tab05-08"><span>Q</span>
              <p>アカウントを複数作っても良い？</p>
            </label>
            <div class="cp_actab-content">
              <div class="cp_actab-content__inner">
                <span>A</span>
                <p>ChibiJobではアカウントの作成をお一人様1アカウントまでとさせていただいております。<br>
                  お一人様で複数のアカウントをお持ちの場合には、不正利用とみなされる場合がございますので、ご注意ください。<br>
                  なお、ご家族でご利用の際は、それぞれのアカウントを作成していただき、それぞれの情報をご登録いただきますようお願いいたします。<br>
                  <br>
                  現在、複数アカウントをお持ちの方は、1アカウントを除いた他アカウントの退会のお手続きをお願いいたします。</p>
              </div>
            </div>
          </div>
          <div class="cp_actab">
            <input id="tab05-09" type="checkbox" name="tabs">
            <label for="tab05-09"><span>Q</span>
              <p>退会したい</p>
            </label>
            <div class="cp_actab-content">
              <div class="cp_actab-content__inner">
                <span>A</span>
                <p><router-link to="/profile/delete-user">こちら</router-link>より退会のお手続きをお願いいたします。</p>
              </div>
            </div>
          </div>
          <div class="cp_actab">
            <input id="tab05-10" type="checkbox" name="tabs">
            <label for="tab05-10"><span>Q</span>
              <p>退会した登録情報を元に戻せる？</p>
            </label>
            <div class="cp_actab-content">
              <div class="cp_actab-content__inner">
                <span>A</span>
                <p>退会が完了したアカウントの登録を元に戻すことはできません。<br>退会される際はご注意いただきますようお願いいたします。</p>
              </div>
            </div>
          </div>
          <div class="cp_actab">
            <input id="tab05-11" type="checkbox" name="tabs">
            <label for="tab05-11"><span>Q</span>
              <p>再入会はできる？</p>
            </label>
            <div class="cp_actab-content">
              <div class="cp_actab-content__inner">
                <span>A</span>
                <p>一度退会されて再入会される場合は、一定期間空けて頂く必要がございます。<br>
                  退会日から30日経過した次月の2日以降に新規ご登録のお手続きをお願いいたします。<br>
                  その前に再登録を行うとエラーになってしまう可能性がございます。<br>
                  例）9/25に退会→11/2より再入会（新規登録）可能</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Common from '@/mixins/common.mixin'
import { mapState } from 'vuex'

export default {
  name: 'Help',

  mixins: [Common],

  data () {
    return {
      menu: [
        {
          id: 1,
          name: 'ChibiJob全般について',
          hash: 'faq01'
        },
        {
          id: 2,
          name: 'ポイントについて',
          hash: 'faq02'
        },
        {
          id: 3,
          name: 'ポイント交換について',
          hash: 'faq03'
        },
        {
          id: 4,
          name: '広告利用について',
          hash: 'faq04'
        },
        {
          id: 5,
          name: '会員登録情報について',
          hash: 'faq05'
        }
      ]
    }
  },

  mounted () {
    // hide app's loading after page render
    if (Common.methods.checkWebview()) {
      Common.methods.triggerEventApp('PAGE_RENDERED', {})
    }
  },
  computed: {
    ...mapState('auth', ['profile']),
    isHasCaution () {
      return !this.checkWebview() && ((this.profile && this.profile.skip_regis_info) || this.checkMobile())
    }
  },
  methods: {
    scrollFix (hashbang) {
      const header = document.getElementById(hashbang)
      const yCoordinate = header.getBoundingClientRect().top + window.pageYOffset
      let yOffset = 0

      // check device SP plus offset top is covered by header
      if (window.matchMedia('(max-width: 767px)').matches) {
        yOffset -= 45
      }

      window.scrollTo({
        top: yCoordinate + yOffset,
        behavior: 'smooth'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
//------------------------------------------------------------------ Color ---*/
$orange: #C66C44;
$orange-gray: #D98F6D;
$orange-bg: #F5F0ED;
//------------------------------------------------------------ Media mixin ---*/

@function vw($num) {
  $maxWidth: 1128; //レイアウト最大幅
  // $val_vw: round($num / $maxWidth * 100 * 1000) / 1000;
  $val_vw: calc(round($num / $maxWidth * 100 * 1000) / 1000);
  $val_max: round($maxWidth * ($val_vw / 100)) * 1px;
  @return unquote('min(#{$val_vw * 1vw}, #{$val_max})');
}

$sp: 767px;
@mixin sp {
  @media (max-width: ($sp)) {
    @content;
  }
}

$viewport: 767;
@function pv($num, $width: $viewport) {
  // @return (1vw * $num / $width * 100);
  @return calc(1vw * $num * 100 / $width);
}

// ------------------- Contents ---*/
.a1g {
  font-family: $font-family-title;
}
#help {
  max-width: 1160px;
  padding: 0 15px vw(100);
  width: 100%;
  background-color: #fff;
  margin: 0 auto;
  @media #{$info-phone} {
    max-width: 100%;
    padding: 0 18px 10vw;
  }

  h1 {
    font-size: vw(36);
    color: $orange;
    text-align: center;
    margin: vw(100) auto vw(55);
    @media #{$info-phone} {
      font-size: pv(64);
      margin: 17vw auto 10vw;
    }
    &.padding-has-caution {
      @media #{$info-phone} {
        padding-top: 50px;
      }
    }
    &.margin-webview {
      margin: 10vw auto;
    }
  }

  .wrapper {
    max-width: 1136px;
    width: 100%;
    background-color: #fff;
    margin: 0 auto;
    position: relative;
    @media #{$info-phone} {
      max-width: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
    }

    .nav {
      height: fit-content;
      position: sticky;
      float: left;
      top: 215px;
      margin: 0;
      max-width: 264px;
      border-radius: 6px;
      background-color: #fff;
      padding: vw(24);
      box-shadow: 0 12px 24px rgba(0, 0, 0, 0.08);
      @media #{$info-phone} {
        position: static;
        top: 0;
        margin: 0 auto;
        width: 100%;
        max-width: 100%;
        padding: 5vw;
        text-align: center;
        margin-bottom: 40px;
      }

      .ttl_en {
        @include font-size(25px);
        color: $orange;
        line-height: 1;
        font-weight: bold;
        letter-spacing: 0.1em;
        margin-bottom: 15px;
        @media #{$info-phone} {
          font-size: 25px;
          margin-bottom: 20px;
        }
      }

      .ttl_jp {
        @include font-size(22px);
        line-height: 1;
        font-weight: bold;
        letter-spacing: 0.1em;
        margin-bottom: vw(40);
        position: relative;
        @media #{$info-phone} {
          font-size: pv(36);
          margin-bottom: 10vw;
          position: relative;
        }
      }

      .ttl_jp:after {
        content: "";
        background-color: $orange-gray;
        width: vw(24);
        height: vw(4);
        position: absolute;
        bottom: -20px;
        left: 0;
        @media #{$info-phone} {
          width: 10vw;
          height: .5vw;
          bottom: -4vw;
          left: 50%;
          transform: translateX(-50%);
          -webkit-transform: translateX(-50%);
          -ms-transform: translateX(-50%);
        }
      }

      ul {
        display: block;
        @media #{$info-phone} {
          width: 80vw;
          margin: 0 auto;
        }
      }

      li {
        font-size: vw(16);
        line-height: 1;
        border-bottom: 1px dotted #E7E5DF;
        padding: vw(15) 0 vw(15) vw(20);
        position: relative;
        @media #{$info-phone} {
          font-size: pv(36);
          padding: 3vw 0 3vw 7vw;
          text-align: left;
        }

        a {
          text-decoration: none;
          color: #282828;
        }
      }

      li:last-child {
        border: none;
      }

      li:before {
        content: "";
        background-image: url("../assets/images/page-other/menu_list_ico.svg");
        background-repeat: no-repeat;
        background-size: contain;
        width: vw(14);
        height: vw(14);
        position: absolute;
        top: vw(15);
        left: 0;
        @media #{$info-phone} {
          width: 4.5vw;
          height: 4.5vw;
          top: 3vw;
        }
      }
    }

    .contents {
      max-width: 840px;
      margin-left: vw(295);
      @media #{$info-phone} {
        margin-left: 0;
      }

      #faq01,#faq02,#faq03,#faq04,#faq05 {
        margin-bottom: vw(80);
        @media #{$info-phone} {
          margin-bottom:10vw;
        }
      }

      .box {
        width: 100%;
        border-radius: 6px;
        border: 6px solid $orange;
        background-color: $orange-bg;
        margin: 0 0 vw(80);
        @media #{$info-phone} {
          border: 3px solid $orange;
          margin: 0 0 10vw;
        }

        .ttl {
          font-size: vw(18);
          line-height: 1;
          font-weight: bold;
          letter-spacing: 0.1em;
          padding: vw(40) 0 vw(40) vw(90);
          border-bottom: 1px solid $orange;
          position: relative;
          @media #{$info-phone} {
            font-size: pv(36);
            padding: 7vw 0;
            text-align: center;
          }
        }

        .ttl:before {
          content: "";
          background-image: url("../assets/images/page-other/faq.svg");
          background-repeat: no-repeat;
          background-size: contain;
          width: vw(152);
          height: vw(102);
          position: absolute;
          top: vw(15);
          left: vw(15);
          z-index: 3;
          @media #{$info-phone} {
            width: 30vw;
            height: 19.4vw;
            top: 2vw;
            left:2vw;
          }
        }
      }
    }
  }
}

//--------------------------------------------------------------- ACORDION ---*/

.cp_actab {
  position: relative;
  overflow: hidden;
  width: 100%;
  margin: 0 auto;
  border-bottom: 1px solid $orange;
}

.cp_actab:last-child {
  border-bottom: none;
}

.cp_actab input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.cp_actab label {
  font-weight: bold;
  position: relative;
  padding: vw(25) vw(50);
  cursor: pointer;
  margin: 0;
  background: $orange-bg;
  text-align: left;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  @media #{$info-phone} {
    padding: 5vw 10vw 5vw 5vw;
  }

  span {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background: $orange;
    font-size: vw(16);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
    @media #{$info-phone} {
      width: pv(48);
      height: pv(48);
      font-size: pv(28);
      margin-right: 3vw;
      flex-shrink: 0;
    }
  }

  p {
    font-size: vw(16);
    line-height: 1.9;
    @media #{$info-phone} {
      font-size: pv(32);
      line-height: 1.3;
      margin-top: .5vw;
    }
  }
}

.cp_actab .cp_actab-content {
  overflow: hidden;
  max-height: 0;
  -webkit-transition: max-height 0.35s;
  transition: max-height 0.35s;
}

.cp_actab-content__inner {
  padding: vw(5) vw(50) vw(25);
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  span {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    border: 1px solid $orange;
    background-color: #fff;
    font-size: vw(16);
    color: $orange;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
    box-sizing: border-box;
    flex-shrink: 0;
    @media #{$info-phone} {
      width: pv(48);
      height: pv(48);
      font-size: pv(28);
      margin-right: 3vw;
    }
  }

  p {
    font-size: vw(16);
    line-height: 1.9;
    @media #{$info-phone} {
      font-size: pv(32);
      line-height: 1.7;
      margin-top: 0;
    }

    a {
      text-decoration: underline;
      color: #282828;
    }
  }

}

.cp_actab input:checked~.cp_actab-content {
  max-height: 20000px;
}

.cp_actab label::after {
  line-height: 3;
  position: absolute;
  top: calc(50% - 7px);
  right: 30px;
  display: block;
  width: 14px;
  height: 14px;
  /* -webkit-transition: all 0.35s;
  transition: all 0.35s; */
  text-align: center;
  @media #{$info-phone} {
    top: 6vw;
    right: 2vw;
    width: 3.7vw;
    height: 3.7vw;
  }
}

.cp_actab input[type=checkbox]+label::after {
  content: '';
  background-image: url("../assets/images/page-other/ac_close.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.cp_actab input[type=checkbox]:checked+label::after {
  content: "";
  background-image: url("../assets/images/page-other/ac_open.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: absolute;
  top: calc(50% - 7px);
  right: 30px;
  display: block;
  width: 14px;
  height: 14px;
  @media #{$info-phone} {
    top: 6vw;
    right: 2vw;
    width: 3.7vw;
    height: 3.7vw;
  }
}

</style>
